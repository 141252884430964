<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Nombre del negocio"
          maxlength="100"
          placeholder="Nombre del negocio"
          v-model="form.nombre_negocio"
          focus
        />
      </div>
      <div class="column">
        <input-base
          v-if="!row"
          rules="required"
          label="Dominio"
          maxlength="100"
          placeholder="nombre_corto"
          v-model="form.subdominio"
        />
        <input-base
          v-else
          label="Dominio"
          rules="required"
          maxlength="100"
          placeholder="nombre_corto"
          :value="form.subdominio"
          disabled="disabled"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="RUC"
          maxlength="11"
          placeholder="RUC"
          v-model="form.ruc"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Nombres"
          maxlength="150"
          placeholder="Nombres completos"
          v-model="form.nombres"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Apellidos"
          maxlength="200"
          placeholder="Apellidos completos"
          v-model="form.apellidos"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Nombre de usuario"
          maxlength="100"
          placeholder="Nombre de usuario"
          v-model="form.username"
        />
      </div>
      <div class="column">
        <input-base
          :rules="!row ? `required` : ``"
          label="Contraseña"
          maxlength="150"
          placeholder="Contraseña"
          v-model="form.password"
          type="password"
        />
      </div>
      <div class="column">
        <input-base
          rules="required|email"
          label="E-mail"
          maxlength="150"
          placeholder="Correo Electrónico"
          v-model="form.email"
        />
      </div>
    </div> 
    
    <div>
      <b-button
        native-type="submit"
        type="is-success"
        icon-left="save"
        :loading="loading"
        class="mt-3"
        >{{ row ? `Guardar cuenta` : `Crear cuenta` }}</b-button
      >
    </div>
  </form-observer>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed: {
    row(){
      return this.$store.getters[`dashboard/row`]
    }
  },
  created() {
    if(this.row){
      this.form = {...this.row}
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const action = this.row ? `UpdateRequest` : `CreateRequest`
      const url = await this.$store.dispatch(`dashboard/${action}`, this.form);
      this.loading = false;
      if(url){
        if(!this.row){
          window.open(url, '_blank');
          this.form = {}
          this.$refs.observer.reset();
        }
        this.$store.dispatch(
          "app/setToastSuccess",
          `La cuenta "${url}" se acaba de guardar.`
        );
      }
    },
  },
};
</script>
