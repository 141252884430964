<template>
  <div>
    <title-bar
      :items="[
        { title: 'Cuentas', to: `/` },
        { title: title_page },
      ]"
    >
    </title-bar>
    <div class="box">
      <FormCreate />
    </div>
  </div>
</template>

<script>
import FormCreate from "../components/FormCreate";
export default {
  components: {
    FormCreate,
  },
  computed:{
    title_page(){
      const {id} = this.$route.query
      return id ? `Editar` : `Nuevo`
    },
  },
};
</script>